const Items = [
  {
    id: 1,
    img1: "https://i.ibb.co/ygvLnKn/minimalist-img-3-364x492.jpg",
    img2: "https://i.ibb.co/VW0r4Tf/minimalist-img-31-364x492.jpg",
    name: "Oversized check overshirt",
    price: 546,
  },
  {
    id: 2,
    img1: "https://i.ibb.co/tCKthsB/minimalist-img-2-1.jpg",
    img2: "https://i.ibb.co/ZmBBLJx/minimalist-img-21.webp",
    name: "Long sleeve checked shirt",
    price: 96,
  },
  {
    id: 3,
    img1: "https://i.ibb.co/QHBzFhv/minimalist-img-51-364x492.jpg",
    img2: "https://i.ibb.co/GTjhWTF/minimalist-img-61-364x492.jpg",
    name: "Oversizeds check overshirt",
    price: 240,
  },
  {
    id: 4,
    img1: "https://i.ibb.co/NxMKQSW/minimalist-img-9-364x492.jpg",
    img2: "https://i.ibb.co/GWChchM/minimalist-img-91-364x492.jpg",
    name: "Ripped midi skirt",
    price: 256,
  },
  {
    id: 5,
    img1: "https://i.ibb.co/ySwQHJw/minimalist-img-13-364x492.jpg",
    img2: "https://i.ibb.co/m8Ts6Vk/minimalist-img-131-364x492.jpg",
    name: "Joggers with mesh detail",
    price: 146,
  },
  {
    id: 6,
    img1: "https://i.ibb.co/8Y20hY2/minimalist-img-12-364x492.jpg",
    img2: "https://i.ibb.co/fqRBj2t/minimalist-img-121-364x492.jpg",
    name: "Zebra print midi skirt",
    price: 106,
  },
  {
    id: 7,
    img1: "https://i.ibb.co/GT6GPCj/coat1-2-364x492.webp",
    img2: "https://i.ibb.co/2SzNzZ1/coat1-364x492.webp",
    name: "Flared ribbed trousers",
    price: 246,
  },
  {
    id: 8,
    img1: "https://i.ibb.co/9bKsRYv/minimalist-img-7-jpg.webp",
    img2: "https://i.ibb.co/qMXqGjQ/minimalist-img-71-jpg.webp",
    name: "Floral midi skirt",
    price: 516,
  },
  {
    id: 9,
    img1: "https://i.ibb.co/Y8rzvjL/minimalist-img-15-364x492.jpg",
    img2: "https://i.ibb.co/zQs4FnL/minimalist-img-151-364x492.jpg",
    name: "Quilted bomber jacket",
    price: 99,
  },
  {
    id: 10,
    img1: "https://i.ibb.co/3cRjBJc/minimalist-img-8-364x492.jpg",
    img2: "https://i.ibb.co/8PKzZpX/minimalist-img-81-364x492.jpg",
    name: "Faux suede bomber jacket",
    price: 116,
  },
  {
    id: 11,
    img1: "https://i.ibb.co/dtBkZ2z/5814619742-2-4-1-jpg.webp",
    img2: "https://i.ibb.co/tzgwn6q/5814619742-2-3-1-1000x1280.jpg",
    name: "Oversizede check overshirt",
    price: 546,
  },
  {
    id: 12,
    img1: "https://i.ibb.co/HNQdJ1Z/0419492742-2-4-3-jpg.webp",
    img2: "https://i.ibb.co/RT19HQC/0419492742-2-3-1-1000x1282.jpg",
    name: "Long sleeve checked shirt",
    price: 96,
  },
  {
    id: 13,
    img1: "https://i.ibb.co/Ld0J8mG/5606326812cvcv-2-4-3-jpg.webp",
    img2: "https://i.ibb.co/tmMyRWP/5606326812-2-3-3-1000x1282.jpg",
    name: "Oversizedf check overshirt",
    price: 240,
  },
  {
    id: 14,
    img1: "https://i.ibb.co/rFpGKm1/5569315306-xcxc2-4-3-jpg.webp",
    img2: "https://i.ibb.co/yB3SLw1/5569315306-2-3-3-1000x1282.jpg",
    name: "Dress With Shirring And Tie",
    price: 152.54,
  },
  {
    id: 15,
    img1: "https://i.ibb.co/7NXX4M6/brand-product-13-jpg.webp",
    img2: "https://i.ibb.co/BCzStyh/brandc34v-product-1-1000x1336-jpg.webp",
    name: "Short Sleeve Cardigan",
    price: 92.55,
  },
  {
    id: 16,
    img1: "https://i.ibb.co/pnWP7v2/0248251433-1-1-3-1000x1282.jpg",
    img2: "https://i.ibb.co/tqF1BfQ/0248251433-2-3-3-1000x1282.jpg",
    name: "Balloon Fit Jeans",
    price: 230.61,
  },
  {
    id: 17,
    img1: "https://i.ibb.co/nrB2Sn6/0040847800-1-1-3-1000x1282.jpg",
    img2: "https://i.ibb.co/g4bV1yb/0040847800-2-3-3-1000x1282.jpg",
    name: "Black Flare Jeans",
    price: 35.48,
  },
  {
    id: 18,
    img1: "https://i.ibb.co/9bPj5qg/shirts1-xcx1-1000x1198.jpg",
    img2: "https://i.ibb.co/R2D8YjF/shirt1-xcs1-1000x1198.jpg",
    name: "Shirts Isolated",
    price: 60.99,
  },
  {
    id: 19,
    img1: "https://i.ibb.co/kcp414P/0150220250-1-1-3-1000x1282.jpg",
    img2: "https://i.ibb.co/ncYmZdf/0150220250-2-3-3-1000x1282.jpg",
    name: "White Flare Jeans",
    price: 98,
  },
  {
    id: 20,
    img1: "https://i.ibb.co/fQXkygk/5275777428-1-1-3-1000x1282.jpg",
    img2: "https://i.ibb.co/ThmH8rm/5275777428-2-3-3-1000x1282.jpg",
    name: "Jogger Jeans",
    price: 87,
  },
  {
    id: 21,
    img1: "https://i.ibb.co/DCH5R7t/5435504607-1-1-3-1000x1282.jpg",
    img2: "https://i.ibb.co/WPk6jhD/5435504607-2-3-3-1000x1282.jpg",
    name: "Baby Doll Flower Dress",
    price: 107.23,
  },
  {
    id: 22,
    img1: "https://i.ibb.co/1TKhSV9/0150220400-1-1-3-1000x1282.jpg",
    img2: "https://i.ibb.co/fNtSXsv/0150220400-2-3-3-1000x1282.jpg",
    name: "Wide-Leg Jeans",
    price: 75.58,
  },
  {
    id: 23,
    img1: "https://i.ibb.co/BrpFRNW/0040847818-2-4-3-1000x1282.jpg",
    img2: "https://i.ibb.co/0CH6B3H/0040847818-2-3-3-1000x1282.jpg",
    name: "Gray Flare Jeans",
    price: 46,
  },
  {
    id: 24,
    img1: "https://i.ibb.co/nssRWyW/6703777712-2-4-3-1000x1282.jpg",
    img2: "https://i.ibb.co/GQNC0tX/6703777712-1-1-3-1000x1282.jpg",
    name: "Short-Sleeved White Corset",
    price: 92.55,
  },
];

export default Items;
