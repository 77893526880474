import React, { useState } from "react";
import Image from "next/image";

import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Slideshow from "../components/HeroSection/Slideshow";
import OverlayContainer from "../components/OverlayContainer/OverlayContainer";
import Card from "../components/Card/Card";
import TestiSlider from "../components/TestiSlider/TestiSlider";
import { itemType } from "../context/cart/cart-types";

// /bg-img/ourshop.png
import ourShop from "../public/bg-img/ourshop.png";
import Items from "@/components/Util/Items";

type Props = {
  products: itemType[];
};

const Home: React.FC<Props> = () => {
  const [currentItems, setCurrentItems] = useState(Items);

  return (
    <>
      {/* ===== Header Section ===== */}
      <Header />

      {/* ===== Carousel Section ===== */}
      <Slideshow />

      <main id='main-content' className='-mt-20'>
        {/* ===== Category Section ===== */}
        <section className='w-full h-auto py-10 border border-b-2 border-gray100'>
          <div className='app-max-width app-x-padding h-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4'>
            <div className='w-full sm:col-span-2 lg:col-span-2'>
              <OverlayContainer
                imgSrc='/bg-img/banner_minipage1.jpg'
                imgSrc2='/bg-img/banner_minipage1-tablet.jpg'
                imgAlt='New Arrivals'
              />
            </div>
            <div className='w-full'>
              <OverlayContainer
                imgSrc='/bg-img/banner_minipage2.jpg'
                imgAlt='Women Collection'
              />
            </div>
            <div className='w-full'>
              <OverlayContainer
                imgSrc='/bg-img/banner_minipage3.jpg'
                imgAlt='Men Collection'
              />
            </div>
          </div>
        </section>

        {/* ===== Best Selling Section ===== */}
        <section className='app-max-width w-full h-full flex flex-col justify-center mt-16 mb-20'>
          <div className='flex justify-center'>
            <div className='w-3/4 sm:w-1/2 md:w-1/3 text-center mb-8'>
              <h2 className='text-3xl mb-4'>Best selling</h2>
              <span>
                Here are some of our best selling products. Explore yourself in
                the latest trends.
              </span>
            </div>
          </div>
          <div className='grid grid-cols-2 md:grid-cols-4 gap-x-4 lg:gap-x-12 gap-y-6 mb-10 app-x-padding'>
            <Card key={currentItems[1].id} item={currentItems[1]} />
            <Card key={currentItems[2].id} item={currentItems[2]} />
            <Card key={currentItems[3].id} item={currentItems[3]} />
            <Card key={currentItems[4].id} item={currentItems[4]} />
          </div>
        </section>

        {/* ===== Testimonial Section ===== */}
        <section className='w-full hidden h-full py-16 md:flex flex-col items-center bg-lightgreen'>
          <h2 className='text-3xl'>Testimonials</h2>
          <TestiSlider />
        </section>

        {/* ===== Featured Products Section ===== */}
        <section className='app-max-width app-x-padding my-16 flex flex-col'>
          <div className='text-center mb-6'>
            <h2 className='text-3xl'>Featured products</h2>
          </div>
          <div className='grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-x-4 gap-y-10 sm:gap-y-6 mb-10'>
            {currentItems.map((item) => (
              <Card key={item.id} item={item} />
            ))}
          </div>
        </section>

        <div className='border-gray100 border-b-2'></div>

        {/* ===== Our Shop Section */}
        <section className='app-max-width mt-16 mb-20 flex flex-col justify-center items-center text-center'>
          <div className='textBox w-3/4 md:w-2/4 lg:w-2/5 mb-6'>
            <h2 className='text-3xl mb-6'>Our shop</h2>
            <span className='w-full'>
              Stop by our stores to learn the stories behind our products, get a
              personal styling session, or shop the latest in person. See our
              store locations.
            </span>
          </div>
          <div className='w-full app-x-padding flex justify-center'>
            <Image src={ourShop} alt='Our Shop' />
          </div>
        </section>
      </main>

      {/* ===== Footer Section ===== */}
      <Footer />
    </>
  );
};

export default Home;
